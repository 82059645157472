import React, { lazy, Suspense, Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { LoadingGif } from "./components/elements/Loading";
import { Provider } from "react-redux";
import store from "./store";

const Alerts = lazy(() => import('./components/elements/Alerts'));
const HomePage = lazy(() => import('./components/Pages/HomePage'))



const App = () => {

  return (
    <Provider store={store}>
      <Router>
        <Suspense fallback={LoadingGif}>
          <Fragment>
            <section>
              <Alerts />
              <Switch>
                <Route path='/' component={HomePage}/>
                <Redirect from="*" to="/" />
              </Switch>
            </section>
          </Fragment>
        </Suspense>
      </Router>
    </Provider>
  );
}


export default App;