import {
    ARTISTS_LOADED,
    ARTIST_LOADED,
    ARTIST_FAILED,
    ARTIST_LOADING
} from "../utils/consts";

const initialState = {
    artists: [],
    artist:null,
    loading:true,
    users:[]
};
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case ARTIST_LOADING:
        return {
          ...state,
          artist:null,
          loading: true
        };
      case ARTISTS_LOADED:
        return {
          ...state,
          loading: false,
          artists: payload
        };
      case ARTIST_LOADED:
        return {
          ...state,
          loading: false,
          artist: payload
        };
      case ARTIST_FAILED:
        return {
          ...state,
          ...payload,
          loading: false
        };
      default:
        return state;
    }
  }
