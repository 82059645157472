import { combineReducers } from "redux";
import alert from "./alert";
import register from "./register";
import artist from './artist';

export default combineReducers({
  alert,
  register,
  artist
});
